import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { i18n } from "@/plugins/i18n";
import { messagesService } from "@/services/messages.service";
import { templatesService } from "@/services/templates.service";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";
import { TemplateModelState } from "./templateManager.types";

type TemplateModelActionContext = ActionContext<TemplateModelState, RootState>;
type TemplateModelActionTree = ActionTree<TemplateModelState, RootState>;

export const actions: TemplateModelActionTree = {
  async loadTemplates(context: TemplateModelActionContext): Promise<any> {
    try {
      context.commit("setLoading", true);
      const templates = await templatesService.get();
      context.commit("setTemplates", templates);
      const pagination: TablePagination = await templatesService.getPagination();
      context.commit("setPagination", pagination);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async saveTemplateModel(
    context: TemplateModelActionContext,
    template: TemplateEditor.TemplateModel
  ): Promise<any> {
    try {
      if (template.id) {
        // @ts-ignore
        template._method = "PUT";
        await templatesService.put(template, template);
      } else {
        await templatesService.post(template);
      }
      messagesService.renderSuccessMessage("template_model_saved");
      context.dispatch(
        "RouterModule/go",
        { name: "template-manager" },
        { root: true }
      );
    } catch (e) {
      if (e.response === undefined) {
        messagesService.renderErrorMessage(i18n.t("template_server_error"));
      } else {
        messagesService.renderErrorMessage(e);
      }
    }
  },
  async deleteTemplateModel(
    context: TemplateModelActionContext,
    template: TemplateEditor.TemplateModel
  ): Promise<any> {
    try {
      await templatesService.delete(template);
      messagesService.renderSuccessMessage("template_model_deleted");
      context.dispatch("loadTemplates");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },
  async deleteMultiple(
    context: TemplateModelActionContext,
    templates: TemplateEditor.TemplateModel[]
  ): Promise<any> {
    try {
      await templatesService.deleteMultiple(templates);
      context.dispatch("loadTemplates");
    } catch (e) {
      messagesService.showMessage(
        "fas fa-exclamation-circle",
        "server_error",
        "error"
      );
    }
  },
  unsetTemplate(context: TemplateModelActionContext): void {
    context.commit("setTemplate", null);
  }
};
